import { useMotionValue } from "framer-motion";
import { useMotionTemplate, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { useEffect } from "react";

export const AuraPointer = ({
  className,
}: {
  text?: string;
  className?: string;
}) => {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  useEffect(() => {
    const onMouseMove = ({ clientX, clientY }: any) => {
      mouseX.set(clientX);
      mouseY.set(clientY);
    };

    window.addEventListener("mousemove", onMouseMove, { passive: true });

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const radialGradientAura = useMotionTemplate`radial-gradient(300px at ${mouseX}px ${mouseY}px, rgba(250,250,250,0.10), transparent 80%)`;

  return (
    <>
      <motion.div
        className={cn(
          "pointer-events-none inset-0 z-0 transition duration-300 lg:fixed",
          className
        )}
        style={{
          background: radialGradientAura,
        }}
      />
    </>
  );
};
